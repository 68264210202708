main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10%;
}

.chatbox {
  position: fixed;
  z-index: 650;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

th {
  background: none !important;
}

.MuiChip-label {
  white-space: normal !important;
}

.MuiChip-root {
  height: fit-content !important;
  padding: 5px;
  white-space: pre-line;
}

#chip-value {
  white-space: pre-line;
  overflow: visible !important;
  min-height: 120px;
  height: fit-content !important;
}

.MuiDialog-paper {
  z-index: 5000 !important;
}
