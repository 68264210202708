.cards-drop-preview {
  background-color: rgba(150, 150, 200, 0.103);
  border: 1px dashed #abc;
  border-radius: 15px;
  margin: 5px 5px 5px 5px;
}
.card-ghost {
  transition: transform 0.3s ease;
  transform: rotateZ(2deg);
}

.card-ghost-drop {
  transition: transform 0.3s ease;
  transform: rotateZ(0deg);
}
