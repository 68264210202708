.flowbox {
  z-index: 2000;
}

.chatbox {
  z-index: 1500;
}

.agent-select {
  z-index: 1000;
  position: fixed;
  left: 50%;
  top: 15%;
  background: rgba(104, 99, 145, 0.4);
  padding: 8px;
}

.autocomplete-search-page{
  z-index: 1000;
  position: fixed !important;
  right: 1.3%;
  top: 15%;
}

.autocomplete-search-message{
  z-index: 1000;
  position: fixed !important;
  right: 1.3%;
  top: 15%;
}

.undo {
  z-index: 1000;
  position: fixed !important;
  left: 0.7%;
  top: 20%;
}

.revert {
  z-index: 1000;
  position: fixed !important;
  left: 0.5%;
  top: 35%;
}

.save {
  z-index: 1000;
  position: fixed !important;
  left: 0.5%;
  top: 50%;
}

.new-node {
  z-index: 1000;
  position: fixed !important;
  left: 0.5%;
  top: 65%;
}

.zoom-group{
  z-index: 1000;
  position: fixed !important;
  left: 0.5%;
  top: 80%;
}

.floating-options {
  position: fixed !important;
}

.condition-header {
  display: inline-block;
  width: 100%;
}

.condition-title {
  margin-bottom: 0;
  margin-top: 0;
  display: inline-block;
}

.condition {
  margin-top: 25px;
  margin-bottom: 25px;
}

.condition-buttons {
  float: right;
}

.condition-buttons svg:hover {
  fill: #4e8cff;
}

.intent-label label {
  display: inline-block;
  vertical-align: middle;
}

.intent-label svg {
  vertical-align: middle;
}

.transition-message {
  margin-bottom: 20px !important;
}

.preset-header {
  display: inline-block;
  width: 100%;
}

.preset-title {
  margin-bottom: 0;
  margin-top: 0;
  display: inline-block;
}

.preset {
  margin-top: 25px;
  margin-bottom: 25px;
}

.preset-buttons {
  float: right;
}

.preset-buttons svg:hover {
  fill: #4e8cff;
}

.clean-params-header {
  display: inline-block;
  width: 100%;
}

.clean-params-title {
  margin-bottom: 0;
  margin-top: 0;
  display: inline-block;
}

.clean-params {
  margin-top: 25px;
  margin-bottom: 25px;
}

.clean-params-buttons {
  float: right;
}

.clean-params-buttons svg:hover {
  fill: #4e8cff;
}

.accordion-rows {
  width: 100%;
}

.react-flow__viewport {
  position: relative;
  z-index: 5 !important;
}

/* Firefox only: */
@supports (-moz-appearance: none) {
  .react-flow__viewport {
    position: fixed;
    z-index: 5 !important;
  }
}

.selected > .react-draggable > .label-box {
  border: 2px solid rgb(255, 123, 0) !important;
}
