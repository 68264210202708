/* Styles for the flowgraph v2 component */

/* Styles for the node connections points */
.reactflow-v2 .react-flow__handle.target {
  width: 10px;
  height: 10px;
  border-radius: 3px;
  background-color: #fff;
}

.reactflow-v2 .react-flow__handle.source {
  width: 10px;
  height: 10px;
  border-radius: 3px;
  background-color: #fff;
}