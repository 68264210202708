.normal {
  border: 2px solid rgb(17, 79, 255);
  border-radius: 3px;
  width: 150px;
}

.cloud {
  width: 120px;
  height: 40px;

  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;

  position: relative;
  margin: 120px auto 20px;
  border-radius: 100px;

  background-color: #f2f9fe !important;
}

.cloud:after,
.cloud:before {
  content: "";
  position: absolute;
  background: #f2f9fe;
  z-index: -1;
}

.cloud:before {
  width: 50px;
  height: 50px;
  top: -24px;
  right: 58px;
  border-radius: 200px;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
}

.cloud:after {
  width: 60px;
  height: 60px;
  top: -30px;
  left: 50px;

  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}

.cloud label {
  color: black !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100px;
  display: block;
}

.quickReplies {
  border: 2px solid #e80000;
  transform: skew(20deg);
}

.quickReplies .react-flow__handle {
  transform: skew(-20deg);
}

.quickReplies div {
  transform: skew(-20deg);
}

.start {
  border: 2px solid rgb(17, 79, 255);
  border-radius: 3px;
  width: 150px;
  border-radius: 20px;
}

.agent {
  border: 2px solid rgb(168, 0, 158);
  border-radius: 3px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
}

.agent div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.transfer {
  border: 2px solid rgb(12, 194, 27);
  border-radius: 3px;
  width: 150px;
}

.feedback {
  border: 2px solid rgb(17, 79, 255);
  border-radius: 3px;
  width: 150px;
}

.selected > .customNode {
  border: 2px solid rgb(255, 123, 0);
}
