.Embed-container{
    height: 900px;
    width: 1500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.Powerbi{
    height: 900px;
    width: 1500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }