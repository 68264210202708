/* LEFT SIDE */
.g274Icon {
  width: 19.8px;
  position: relative;
  height: 22.4px;
}
.g278Icon {
  width: 15.8px;
  position: relative;
  height: 17.1px;
}
.g282Icon {
  width: 10.4px;
  position: relative;
  height: 16.6px;
}
.g286Icon {
  width: 25.1px;
  position: relative;
  height: 16.2px;
}
.path290Icon {
  width: 4.4px;
  position: relative;
  height: 22.4px;
}
.g292Icon {
  width: 15.2px;
  position: relative;
  height: 16.6px;
}
.dotIcon {
  width: 4.7px;
  position: relative;
  height: 4.6px;
}
.isotipoDarwin {
  /*display: flex;*/
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}
.emoji {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  position: relative;
  border-radius: 16px;
  background-color: #fcfdfc;
  border: 1px solid #d5d5e7;
  box-sizing: border-box;
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
}
.titulo1 {
  margin: 0;
  font-family: "Helvética Neue LT Std - 55 Roman";
}

.titulo2 {
  margin: 0;
  font-size: 40px;
  font-family: "Helvética Neue LT Std - 75 Bold";
}

.elUniversoDe {
  width: 384px;
  position: relative;
  font-size: 20px;
  display: flex;
  align-items: center;
  font-family: "Helvetica Neue LT Std - 45 Light";
}

.tituloDescripcion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  font-size: 40px;
  font-family: "Helvética Neue LT Std - 75 Bold";
}

.ttuloDescripcion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 5%;
}

.textField {
  align-self: stretch;
  flex: 1;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 2px solid #7e7e7e;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 56px;
  width: 100%;
}

.form {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}
.atomiconCheckBox {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.recordarme {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  gap: 2px;
  
}

.recordarmeForgot {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #2c2f88;
}
.formRecordarme {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  font-size: 16px;
}

.span {
  color: #2c2f88;
}

.necesitasAyudaParaIniciarSParent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  text-align: left;
  font-size: 14px;
  color: #000;
}

.botForm {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
  font-size: 20px;
  color: #f1f1f1;
}
.content2 {
  margin-top: 15%;
  margin-bottom: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}
.content1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.content {
  position: absolute;
  left: 0px;
  border-radius: 0px 56px 56px 0px;
  background-color: #f1f1f1;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 80px;
  box-sizing: border-box;
  color: #1a1a1a;
  width: 30vw;
}

.alertBox{
  display: flex;
  width: 410px;
  margin-top: 2vh;
  padding: 16px;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid #3A3EB5;
  border-left: 10px solid #3A3EB5;
  background: #FCFDFC;
}

.alertBoxError {
  display: flex;
  width: 410px;
  margin-top: 2vh;
  padding: 16px;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid #9F1919;
  border-left: 10px solid  #9F1919;
  background: #FCFDFC;
}

.infoParent {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.titleParent{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.infoIcon{
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  color: #3A3EB5;
  width: 10%;
}

.infoIconError{
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  color: #9f1919;
  width: 10%;
}

.titleAlert {
  color: #3A3EB5;
  height: 10px;
  margin-bottom: 5%;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.titleAlertError {
  color: #9f1919;
  height: 10px;
  margin-bottom: 5%;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.descriptionAlert{
  color: #282938;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
}

.loginStart {
  width: 100%;
  position: relative;
  background-color: #e8e8e8;
  height: 100vh;
  overflow: hidden;
  text-align: left;
  font-size: 32px;
  color: #f1f1f1;
}

.recordarme {
  font-family: "Helvética Neue LT Std - 75 Bold";
  font-size: 14;
  font-weight: 500;
  word-wrap: break-word;
}

/* CARRUSEL */

.carruselFilter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.14;
  background: linear-gradient(180deg, #2c2f88 0%, #1e1e1d 94.04%);
}

.carrusel {
  position: absolute;
  transform: translateX(13%);
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.carruselImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.carruselImg.active {
  opacity: 1;
}

.carruselImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carruselFilter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.14;
  background: linear-gradient(180deg, #2c2f88 0%, #1e1e1d 94.04%);
}

.next {
  width: 0;
  height: 0;
}

.loremIpsumDolorContainer {
  align-self: stretch;
  position: relative;
}

.textContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.textLogin {
  width: 748px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.body {
  position: absolute;
  bottom: 80px;
  left: calc(50% - 334px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.sliderChild {
  width: 16px;
  position: relative;
  border-radius: 50%;
  background-color: #d5d5e7;
  height: 16px;
}
.sliderItem {
  width: 14px;
  position: relative;
  border-radius: 50%;
  background-color: #a7a7cd;
  height: 14px;
}
.slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.carrouselFirstText {
  font-size: 32px;
  font-family: "Helvetica Neue LT Std - 45 Light";
  color: #f1f1f1;
}

.carrouselSecondText {
  font-size: 32px;
  font-family: "Helvetica Neue LT Std - 65 Medium";
  color: #f1f1f1;
}

.carrouselThirdText {
  font-size: 24px;
  font-family: "Helvetica Neue LT Std - 45 Light";
  color: #f1f1f1;
}

/* 2AUTH */

.twoFAingresaYVerifica {
  width: 384px;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 0;
}

.twoFAingresaYVerificaElCdigoQParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: 16px;
  font-size: 20px;
}

.twoFAverifyCode {
  width: 58px;
  border-radius: 8px;
  background-color: #f1f1f1;
  border: 2px solid #7e7e7e;
  box-sizing: border-box;
  height: 66px;
  overflow: hidden;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 0;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Helvética Neue LT Std - 75 Bold";
  font-size: 24px;
  text-align: center;
}

.twoFAverifyCode:active,
.twoFAverifyCode:focus {
  border: 2px solid var(--primary-azul-evoltis, #2c2f88);
  background: var(--primary-azul-tenue, #d5d5e7);
  box-shadow: 0px 2px 7px 0px rgba(44, 47, 136, 0.6),
    0px 0.77px 2.23px 0px rgba(44, 47, 136, 0.4), 0px 0.163px 0.57px 0px #2c2f88;
  outline: none;
}
.twoFAverifyCode:hover{
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
}

.twoFAverifyCode.blur {
  border: 2px solid #3a3eb5;
}

.twoFAverifyCode.error {
  border: 2px solid var(--advices-error-error-light, #9F1919);
  background: var(--neutral-gris-1-background, #F1F1F1);

}

.separatorToken{
  width: 16px;
  position: relative;
  background-color: #7e7e7e;
  height: 2px;
}

.helper2FaError {
  color:#9F1919;
  font-size: 12px;
  margin-left: 3%;
  margin-top: -2%;
  width: 100%;
  align-items: flex-start;
}

.twoFAform1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.twoFAclickParaReenviar {
  text-decoration: underline;
  color: #3a3eb5;
}

.twoFAnoRecibisteElContainer {
  position: relative;
  width: 100%;
  margin-left: 2%;
  margin-top: -2%;
}

.twoFAform {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  font-size: 14px;
  color: #2d2d2d;
}

.twoFAbotForm {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 399px;
  min-width: 248px;
  gap: 8px;
  text-align: center;
  font-size: 20px;
  color: #7e7e7e;
}

/* DIALOG */

.dialogBlockUser {
  width: 312px;
  min-width: 280px;
  max-width: 560px;
  border-radius: 16px;
  background-color: #e3e3e3;
  border: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialogContactSupport {
  width: 312px;
  min-width: 280px;
  max-width: 560px;
  border-radius: 16px;
  background-color: #e3e3e3;
  border: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialogPretoken {
  width: 315px;
  min-height: 250px;
  border-radius: 16px;
  background-color: #e3e3e3;
  border: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.emailIcon {
  width: 32px;
  position: relative;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
}
.headline {
  align-self: stretch;
  position: relative;
  mix-blend-mode: normal;
  font-size: 28px;
  font-family: "Helvetica Neue LT Std - 65 Medium";
  text-align: center;
  mix-blend-mode: normal;
  color: #1a1a1a;
}
.supportingText {
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 20px;
  color: #2d2d2d;
  text-align: left;
  mix-blend-mode: normal;
  font-family: "Helvetica Neue LT Std - 45 Light";
}
.titleDescription {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 24px 0px;
  gap: 16px;
}

.actions {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 14px;
  color: #2c2f88;
  margin-right: 24px;
  margin-top: 24px;
  margin-bottom: 20px;
}


.twoActions {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #2c2f88;
  margin:24px;
}

.buttonTypeLink{
  cursor: pointer;
  font-family: "Helvetica Neue LT Std";
  font-size: 12px;
  font-style: normal;
  font-weight: 700; 
  line-height: 22px;
  color: #3A3EB5;
}

/*media*/
@media (max-width: 768px) {
  .content {
    width: 100%;
    border-radius: 0px;
  }
  
}

@media (min-width: 768px) and (max-width: 1024px) {
  .content {
    width: 50vw;
  }
  .carrusel {
    transform: translateX(25%);
  }

  .carrouselFirstText {
    margin-left: 20%;
    font-size: 10px;
  }
  .carrouselSecondText {
    font-size: 10px;
  }

  .carrouselThirdText {
    margin-left: 20%;
    font-size: 10px;
  }

  .sliderItem {
    width: 10px;
    height: 10px;
  }

  .sliderChild {
    width: 12px;
    height: 12px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .content {
    width: 40vw;
  }
  .carrusel {
    transform: translateX(18%);
  }
  .carrouselFirstText {
    margin-left: 7%;
    font-size: 18px;
  }

  .carrouselSecondText {
    font-size: 18px;
  }

  .carrouselThirdText {
    margin-left: 7%;
    font-size: 18px;
  }

  .sliderItem {
    width: 12px;
    height: 12px;
  }

  .sliderChild {
    width: 14px;
    height: 14px;
  }

}

@media (max-height: 768px) {
  .elUniversoDe {
    font-size: 17px;
  }
  .tituloDescripcion {
    font-size: 32px;
  }
  .textField {
    height: 28px;
  }
  .g274Icon {
    width: 15px;
    height: 17px;
  }
  .g278Icon {
    width: 12px;
    height: 13px;
  }
  .g282Icon {
    width: 8px;
    height: 13px;
  }
  .g286Icon {
    width: 20px;
    height: 13px;
  }
  .path290Icon {
    width: 4px;
    height: 17px;
  }
  .g292Icon {
    width: 13px;
    height: 13px;
  }
  .dotIcon {
    width: 5px;
    height: 5px;
  }
  .isotipoDarwin {
    gap: 3px;
  }
  .emoji {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    font-size: 20px;
  }
  .formRecordarme {
    font-size: 10px;
  }
  .botForm {
    font-size: 16px;
  }
  .necesitasAyudaParaIniciarSParent {
    font-size: 10px;
  }
  .carrouselFirstText {
    font-size: 20px;
  }
}
