@font-face {
  font-family: "Helvetica Neue LT Std";
  src: url("./assets/fonts/helveticaneueltstd_blk.otf") format("opentype");
}

@font-face {
  font-family: "Helvetica Neue LT Std - 45 Light";
  src: url("./assets/fonts/HelveticaNeueLTStd-Lt.otf") format("opentype");
}

@font-face {
  font-family: "Helvética Neue LT Std - 55 Roman";
  src: url("./assets/fonts/HelveticaNeueLTStd-Roman.otf") format("opentype");
}

@font-face {
  font-family: "Helvetica Neue LT Std - 65 Medium";
  src: url("./assets/fonts/HelveticaNeueLTStd-Md.otf") format("opentype");
}

@font-face {
  font-family: "Helvética Neue LT Std - 75 Bold";
  src: url("./assets/fonts/HelveticaNeueLTStd-Bd.otf") format("opentype");
}

@font-face {
  font-family: "Helvética Neue LT Std - 24px";
  src: url("./assets/fonts/HelveticaNeueLTStd-Md.otf") format("opentype");
}

body {
  margin: 0;
  line-height: normal;
  font-family: "Helvetica Neue LT Std", "Helvetica Neue LT Std - 45 Light",
    "Helvetica Neue LT Std - 55 Roman", "Helvetica Neue LT Std - 65 Medium",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
