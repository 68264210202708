.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chatbox {
  z-index: 1500;
}

.react-logo {
  width: 50%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homeBackground {
  background-color: transparent;
  width: calc(100vw - 2%);
  height: auto;
  overflow: hidden;
  padding: 1%;
}

.headline {
  align-self: stretch;
  position: relative;
  mix-blend-mode: normal;
  font-size: 32px;
  font-family: "Helvetica Neue LT Std - 65 Medium";
  font-weight: 700;
  text-align: start;
  mix-blend-mode: normal;
}

.subtitle {
  align-self: stretch;
  position: relative;
  mix-blend-mode: normal;
  font-size: 28px;
  font-family: "Helvetica Neue LT Std - 65 Medium";
  font-weight: 500;
  text-align: start;
}

.divider {
  width: 100%;
  height: 3px;
  background-color: #e5e5e5;
  display: block;
}

.scenarioDivider {
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
  margin-top: 10;
  display: block;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scenarios {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap; /* Evita que los elementos se envuelvan a una nueva fila */
  gap: 16px; /* Espaciado entre elementos */
  width: 100%;
  height: 350px;
  overflow-x: auto; /* Permite el desplazamiento horizontal */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scenarios::-webkit-scrollbar {
  display: none; /* Oculta la barra de desplazamiento en Chrome, Safari y Opera */
}

.scenario {
  width: 420px; /* Ancho fijo de 420px */
  height: 320px;
  flex: 0 0 auto; /* Previene que los elementos flex se encogen o crecen */
  display: flex;
  flex-direction: column;
  border-radius: 10px !important;
  background-color: #d5d5e7 !important;
}

.documentationCard {
  min-width: 100px;
  max-height: 330px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border-radius: 10px !important;
  background-color: #d5d5e7 !important;
}

.scenario:hover .blurOverlay {
  filter: blur(3px);
}

.documentationCard:hover .blurOverlay {
  filter: blur(3px);
}

.documentationCard:hover .hoverButtons {
  visibility: visible;
  opacity: 1;
}

.blurOverlay {
  transition: filter 0.2s ease;
}

.hoverButtons {
  visibility: hidden;
  /* Inicialmente invisible */
  position: absolute;
  display: flex;
  width: inherit;
  height: inherit;
  margin-left: 55px;
  margin-top: -20px;
  flex-direction: column;
  z-index: 10;
  /* Asegura que el botón esté por encima de la tarjeta */
  opacity: 0;
  /* Inicialmente transparente */
  transition: opacity 0.2s ease, visibility 0.2s ease;
  /* Transición para la opacidad y visibilidad */
}

.hoverButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.scenario:hover .hoverButtons {
  visibility: visible; /* Visible cuando se hace hover */
  opacity: 1; /* Totalmente opaco cuando se hace hover */
  transition: filter 0.2s ease;
}

.scenario:hover .hoverButtons {
  visibility: visible; /* Visible cuando se hace hover */
  opacity: 1; /* Totalmente opaco cuando se hace hover */
  transition: filter 0.2s ease;
}

.cardContent:hover .hoverButtons {
  visibility: visible; /* Visible cuando se hace hover */
  opacity: 1; /* Totalmente opaco cuando se hace hover */
  transition: filter 0.2s ease;
}

.documentationCard {
  width: 400px;
  height: 350px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border-radius: 10px !important;
  background-color: #d5d5e7 !important;
}

.cardContent {
  background-color: #fcfdfc;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titleScenario {
  display: flex;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: #1a1a1a;
}

.titleScenarioModal {
  display: flex;
  font-size: 18px;
  text-align: center;
  color: #1a1a1a;
  margin-top: 12px;
  margin-bottom: 12px;
}

.descriptionScenario {
  display: flex;
  font-size: 15px;
  text-align: start;
  margin-top: 10px;
  margin-bottom: 8px;
  color: #1a1a1a;
}

.lastUpdateScenario {
  display: flex;
  font-size: 14px;
  text-align: start;
  margin-bottom: 14px;
  color: #7e7e7e;
  align-items: flex-end;
}

.helperMessage {
  display: inline-block;
  font-size: 14px;
  text-align: start;
  color: #1a1a1a;
  margin-left: 15px;
}

.headerTitle {
  display: flex;
  font-size: 18px;
  text-align: center;
  color: #1a1a1a;
}

.paper {
  width: 40%;
  background-color: #fcfdfc !important;
  border: "2px solid #000";
  box-shadow: 24;
}

.knowledge {
  display: flex !important;
}

.chip {
  width: 100%;
}

.dialogActions {
  display: flex;
  justify-content: space-between;
  margin-top: 2;
}

.scenarioTextfield {
  margin: 0;
}

/* Home FAB button*/

.fab {
  background-color: #3a3eb5; /* Color de fondo azul */
  color: white; /* Color de iconos y texto en blanco */
}

.fab:hover {
  background-color: #3a3eb5; /* Mantiene el color de fondo azul en hover */
  color: white; /* Mantiene iconos y texto en blanco durante el hover */
}

.fab-buttons {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
