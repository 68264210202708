.dragging-node {
  position: absolute;
  background-color: #ff4000;
  border: 1px solid #000;
  border-radius: 10px;
  width: 150px;
  height: 50px;
  top: -1000px; /* Posición inicial fuera de la pantalla */
  left: -1000px;
  line-height: 40px;
  text-align: center;
}