@media (max-width: 1440px) {
  .button-intent-download {
    margin-left: 27vw;
  }
}

@media (min-width: 1441px) and (max-width: 1919px) {
  .button-intent-download {
    margin-left: 36vw;
  }
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .button-intent-download {
    margin-left: 42vw;
  }
}

@media (min-width: 2560px) {
  .button-intent-download {
    margin-left: 54vw;
  }
}
