.modalContentTitle {
  display: flex;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
}

.modalDescription {
  display: flex;
  font-size: 15px;
  text-align: start;
  margin-top: 4px;
  margin-bottom: 0px;
}

.helperMessage {
  display: inline-block;
  font-size: 14px;
  text-align: start;
  margin-left: 15px;
}

.headerTitle {
  display: flex;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.modalDivider {
  width: 100%;
  height: 1px;
  margin-top: 10; 
  display: block;
}

.paper {
  width: 40%;
  border: "2px solid #000";
  border-radius: 16px !important;
  box-shadow: 24;
};

.knowledge {
  display: flex !important;
}

.chip {
  width: 100%;
}

.dialogActions {
  display: flex;
  justify-content: space-between;
  margin-top: 2;
}

.modalTextField {
  margin-top: 0 !important;
  & textarea::placeholder {
    transition: opacity 0.3s ease;
  }
  & textarea:focus::placeholder {
    opacity: 0;
  }
  & input::placeholder {
    transition: opacity 0.3s ease;
  }
  & input:focus::placeholder {
    opacity: 0;
  }
}

.outlined {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.elevated {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.scenario:hover .hoverButtons {
  opacity: 1;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
} 

.modalFooter {
  box-shadow: 0px 1px 3px 0px #0000004D, 0px 4px 8px 3px #00000026;
}